import React from 'react';
import './component-style.css';

import { Card, CardDeck } from 'react-bootstrap';

//IMAGENES
import img_transmision from '../assets/imagenes_servicios/transmision.jpg';
import img_estudio1 from '../assets/imagenes_servicios/estudio1.jpg';
import img_espacio_trabajo from '../assets/imagenes_servicios/espacio-trabajo.jpg';


class Servicios extends React.Component{

    render(){        
        return  <div>
                    <h2 className="titulo-principal">Nuestros Servicios y Soluciones</h2>
                    {/* <ul className="mx-auto text-justify mb-5 contenedor-servicios">
                        <div className="contenedor-info">
                            <li className="mb-5">
                                <hr/>
                                <p>Alquiler de estaciones terrenas portátiles (FlyAway) para transmisiones remotas en vivo con calidad de álta definición en todo el territorio nacional.</p>
                                <div className="text-center">
                                    <img src={img_transmision} alt="img" className="imagen-servicios"/>
                                </div>
                            </li>
                            <li className="mt-5 mb-5">
                                <hr/>
                                <p>Disponemos de estudio de televisión y vistas para puntos de transmisiones (Live Shot).</p>
                                <div className="text-center">
                                    <img src={img_estudio1} alt="img" className="imagen-servicios"/>
                                </div>
                            </li>
                            <li className="mt-5 mb-5">
                                <hr/>
                                <p>Ofrecemos áreas de trabajo para canales de televisión y agencias de prensa con facilidades para la labor periodística, de logística y producción.</p>
                                <div className="text-center">
                                    <img src={img_espacio_trabajo} alt="img" className="imagen-servicios"/>
                                </div>
                                <hr/>
                            </li>
                        </div>
                    </ul> */}

                    <CardDeck className="contenedor-tarjetas-servicios">
                        <Card style={{borderRadius:"10px", padding:"0.2em"}} className="tarjeta-servicio">
                            <Card.Img style={{borderRadius:"5px"}} src={img_transmision} />
                            <Card.Body>
                            <hr/>
                            <Card.Text>
                                Alquiler de estaciones terrenas portátiles (FlyAway) para transmisiones remotas en vivo con calidad de álta definición en todo el territorio nacional.
                            </Card.Text>
                            </Card.Body>
                        </Card>
                        <Card style={{borderRadius:"10px", padding:"0.2em"}} className="tarjeta-servicio">
                            <Card.Img style={{borderRadius:"5px"}} src={img_estudio1} />
                            <Card.Body>
                            <hr/>
                            <Card.Text>
                                Disponemos de estudio de televisión y vistas para puntos de transmisiones (Live Shot).
                            </Card.Text>
                            </Card.Body>
                        </Card>
                        <Card style={{borderRadius:"10px", padding:"0.2em"}} className="tarjeta-servicio">
                            <Card.Img style={{borderRadius:"5px"}} src={img_espacio_trabajo} />
                            <Card.Body>
                            <hr/>
                            <Card.Text>
                                Ofrecemos áreas de trabajo para canales de televisión y agencias de prensa con facilidades para la labor periodística, de logística y producción.
                            </Card.Text>
                            </Card.Body>
                        </Card>
                    </CardDeck>
                </div>
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }
    
}

export default Servicios;