import React, { useEffect } from 'react';
import { Card, CardColumns } from 'react-bootstrap';
import './component-style.css';

import fifa from '../assets/eventos/FIFA.png'
import fiba from '../assets/eventos/fiba.jpg'
import copa_libertadores from '../assets/eventos/copa-libertadores.png'
import copa_sudamericana from '../assets/eventos/copa-sudamericana.png'
import futve from '../assets/eventos/FUTVE.png'
import fvf from '../assets/eventos/fvf.png'
import fvb from '../assets/eventos/fvb.png'
import fvv from '../assets/eventos/fvv.jpg'
import lvbp from '../assets/eventos/LVBP.jpg'
import superliga from '../assets/eventos/superliga.jpg'
import miss_venezuela from '../assets/eventos/miss-venezuela.webp'
import miss_earth from '../assets/eventos/miss-earth.webp'

const Eventos = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <h2 className="titulo-principal">Eventos</h2>
      <CardColumns className="mx-auto mt-5 text-center w-75">
        <Card style={tarjeta_cliente}>
          <Card.Img src={fifa} />
        </Card>
        <Card style={tarjeta_cliente}>
          <Card.Img src={fiba} />
        </Card>
        <Card style={tarjeta_cliente}>
          <Card.Img src={copa_libertadores} />
        </Card>
        <Card style={tarjeta_cliente}>
          <Card.Img src={copa_sudamericana} />
        </Card>
        <Card style={tarjeta_cliente}>
          <Card.Img src={fvf} className='w-75 mx-auto' />
        </Card>
        <Card style={tarjeta_cliente}>
          <Card.Img src={fvb} className='w-75 mx-auto' />
        </Card>
        <Card style={tarjeta_cliente}>
          <Card.Img src={fvv} />
        </Card>
        <Card style={tarjeta_cliente}>
          <Card.Img src={futve} />
        </Card>
        <Card style={tarjeta_cliente}>
          <Card.Img src={lvbp} className='w-75 mx-auto' />
        </Card>
        <Card style={tarjeta_cliente}>
          <Card.Img src={superliga} />
        </Card>
        <Card style={tarjeta_cliente}>
          <Card.Img src={miss_venezuela} />
        </Card>
        <Card style={tarjeta_cliente}>
          <Card.Img src={miss_earth} />
        </Card>
      </CardColumns>
    </div>
  )
}

export default Eventos

const tarjeta_cliente = {
  border: "none",
  width: "90%",
  margin: "auto",
  marginBottom: "3em"
}