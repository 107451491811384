import React from 'react'
import { Alert } from 'react-bootstrap'
import logoULV from '../../assets/logotipo/icono-ulv.png'

const BannerComunicado = ({ text, logo, scrollable, width }) => {
  return (
    <Alert variant='info' style={{ width: width ? width : '85%', margin: 'auto', marginTop: '1em', borderRadius: '10px', boxShadow: '2px 2px 15px rgba(0, 0, 0, 0.125)', textAlign: 'start', userSelect: 'none' }}>
      <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
        {logo &&
          <img src={logoULV} alt='' style={{ height: '25px', width: '25px', marginRight: '1em' }} />
        }
        {scrollable ?
          <marquee>{text}</marquee>
          :
          text
        }
      </div>
    </Alert>
  )
}

export default BannerComunicado