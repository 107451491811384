import React from 'react';
import './component-style.css';

class Conocenos extends React.Component{

    render(){        
        return  <div className="mb-5 pb-5">
                    <h3 className="titulo-principal">UP-Link de Venezuela</h3>
                    <div className="contenedor-info-conocenos">
                        <p>Somos una empresa en el área de telecomunicaciones que se especializa en ofrecer servicios de transmisión satelital de forma eficiente, respondiendo a las necesidades de los clientes a nivel nacional.</p>
                        <p>Nos apoyamos en un equipo humano altamente calificado con una infraestructura y tecnología de punta.</p>
                        <h4 className="titulo mt-5">Misión</h4>
                        <p>Nos destacamos por ofrecer transmisiones satelitales de manera oportuna, confiable y con la mejor tecnología en todo el territorio nacional</p>
                        <h4 className="titulo mt-5">Visión</h4>
                        <p>Ser una empresa de transmisión satelital lider en innovación, que se adapta a las distintas necesidades del cliente ofreciendo constantemente servicios confiables y de máxima calidad.</p>
                    </div>
                </div>
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }
    
}

export default Conocenos;