import React from 'react';

import './component-style.css';

class Pie extends React.Component{

    render(){        
        return  <footer>
                    {/* <div className="contenedor-footer"> */}
                        <h6>© UP-Link de Venezuela</h6>
                        <p>RIF: J-29355952-9</p>
                        <p>Calle 80 entre avenida 36 y 3H. Sector Valle Frío. Maracaibo, Zulia. Venezuela</p>
                        <p>+58-4142708292</p>
                    {/* </div> */}
                </footer>
    }
}

// const estiloPie = {
//     border: "1px solid black",
//     position: "absolute",
//     bottom: 0,
//     width: "100%",
//     height: "2.5rem"  
// }

export default Pie;