import React, { useState, useEffect } from 'react'
import { Modal, ProgressBar, Form, Button } from 'react-bootstrap'
import logoULV from '../../assets/logotipo/icono-ulv.png'
import useWindowDimensions from '../../hooks/useWindowDimensions'

const CalcularConsumoModal = ({ show, onClose }) => {

  const { width } = useWindowDimensions();

  const progressBarMaxValue = 10915

  // Progress bar useState (0 - 100%)
  const [progressBar, setProgressBar] = useState(0);
  // Range sliders useState (units)
  const [textOnlyPost, setTextOnlyPost] = useState(0);
  const [imagePost, setImagePost] = useState(0);
  const [textOnlyMessage, setTextOnlyMessage] = useState(0);
  const [imageMessage, setImageMessage] = useState(0);
  const [videoMessage, setVideoMessage] = useState(0);
  const [voiceMessage, setVoiceMessage] = useState(0);
  const [voiceCall, setVoiceCall] = useState(0);
  const [videoCall, setVideoCall] = useState(0);
  const [musicStreaming, setMusicStreaming] = useState(0);
  const [videoStreaming, setVideoStreaming] = useState(0);
  const [movieStreaming, setMovieStreaming] = useState(0);
  const [mapsUsage, setMapsUsage] = useState(0);
  const [email, setEmail] = useState(0);
  const [emailWithAttachment, setEmailWithAttachment] = useState(0);
  const [webBrowsing, setWebBrowsing] = useState(0);
  const [appDownload, setAppDownload] = useState(0);

  // useEffect for changing progress bar percentage
  useEffect(() => {
    const megaBytes = (
      textOnlyPost * (1 / 5)
      + imagePost * (1 / 2)
      + textOnlyMessage * 0.35
      + imageMessage * 0.1
      + videoMessage * 7
      + voiceMessage * 0.15
      + voiceCall * 2
      + videoCall * 4
      + musicStreaming * 1
      + videoStreaming * 5
      + movieStreaming * 5
      + mapsUsage * (7 / 90)
      + email * (3 / 1000)
      + emailWithAttachment * (3 / 10)
      + webBrowsing * (2 / 5)
      + appDownload * 45
    )
    setProgressBar(megaBytes)
  }, [textOnlyPost, imagePost, textOnlyMessage, imageMessage, videoMessage, voiceMessage, voiceCall, videoCall, musicStreaming, videoStreaming, movieStreaming, mapsUsage, email, emailWithAttachment, webBrowsing, appDownload]);

  const reiniciar = () => {
    setProgressBar(0)
    setTextOnlyPost(0)
    setImagePost(0)
    setTextOnlyMessage(0)
    setImageMessage(0)
    setVideoMessage(0)
    setVoiceMessage(0)
    setVoiceCall(0)
    setVideoCall(0)
    setMusicStreaming(0)
    setVideoStreaming(0)
    setMovieStreaming(0)
    setMapsUsage(0)
    setEmail(0)
    setEmailWithAttachment(0)
    setWebBrowsing(0)
    setAppDownload(0)
  }

  return (
    <Modal show={show} onHide={onClose} size='lg' centered scrollable>
      <Modal.Header closeButton>
        <Modal.Title>Calcular estimado de consumo de internet</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* Information */}
        <div style={{ borderBottom: '0.5px solid #cecece' }}>
          <h5>Información</h5>
          <p>Esta herramienta ayuda a calcular un estimado de consumo de ancho de banda de su servicio de internet en megabytes (MB) y gigabytes (GB) en un periodo de tiempo aproximado de un mes.</p>
        </div>

        {/* Progress bar, max 10752MB (10.5GB) */}
        <div style={{ position: 'sticky', top: '-1em', padding: '1em 0em 1em 0em', marginBlock: '1em', backgroundColor: '#ffffff' }}>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div style={{ flex: width > 575 ? 2 : 1 }}>
              <p>Consumo estimado calculado:</p>
              <ProgressBar striped variant={progressBar <= progressBarMaxValue * 0.5 ? "success" : progressBar <= progressBarMaxValue * 0.85 ? "warning" : "danger"} now={progressBar} min={0} max={progressBarMaxValue} />
            </div>
            <div style={{ display: 'flex', flex: width > 575 ? 0.5 : 1, alignItems: 'center', justifyContent: 'flex-end', flexDirection: 'column' }}>
              <div style={{ padding: '0.125em', border: '1px solid #cecece', borderRadius: '5px', userSelect: 'none' }}>
                <h5 style={{ margin: '0em' }}>{parseFloat(progressBar).toFixed(2)} MB</h5>
                  <p style={{textAlign: 'center', margin: '0px', fontSize: '10px', fontWeight: 'bold'}} >- o -</p>
                <h5 style={{ margin: '0em' }}>{parseFloat(parseFloat(progressBar) / 1024).toFixed(2)} GB</h5>
              </div>
            </div>
          </div>
        </div>

        {/* Range inputs */}
        <Form>
          <Form.Group>
            {/* Publicación en redes sociales (solo texto) */}
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div style={{ flex: 2 }}>
                <Form.Label>Publicación en redes sociales (solo texto)</Form.Label>
                <Form.Control type="range" step={1} min={0} max={100} onChange={(event) => setTextOnlyPost(event.target.value)} value={textOnlyPost} />
                <br />
              </div>
              <div style={{ display: 'flex', flex: 0.5, justifyContent: 'flex-start', alignItems: 'center', padding: '0em, 0.25em, 0em, 0.25em' }}>
                <Form.Control type="number" step={1} min={0} max={100} onChange={(event) => setTextOnlyPost(event.target.value)} value={textOnlyPost} style={{ width: '4.5em', marginLeft: '0.25em', marginRight: '0.25em' }} />
                <p style={{ margin: '0em' }}>Post{parseInt(textOnlyPost) === 1 ? '' : 's'}</p>
              </div>
            </div>

            {/* Publicación en redes sociales (imagen) */}
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div style={{ flex: 2 }}>
                <Form.Label>Publicación en redes sociales (imagen)</Form.Label>
                <Form.Control type="range" step={1} min={0} max={100} onChange={(event) => setImagePost(event.target.value)} value={imagePost} />
                <br />
              </div>
              <div style={{ display: 'flex', flex: 0.5, justifyContent: 'flex-start', alignItems: 'center', padding: '0em, 0.25em, 0em, 0.25em' }}>
                <Form.Control type="number" step={1} min={0} max={100} onChange={(event) => setImagePost(event.target.value)} value={imagePost} style={{ width: '4.5em', marginLeft: '0.25em', marginRight: '0.25em' }} />
                <p style={{ margin: '0em' }}>Post{parseInt(imagePost) === 1 ? '' : 's'}</p>
              </div>
            </div>

            {/* Mensajería instantanea (solo texto) */}
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div style={{ flex: 2 }}>
                <Form.Label>Mensajería instantanea (solo texto)</Form.Label>
                <Form.Control type="range" step={1} min={0} max={500} onChange={(event) => setTextOnlyMessage(event.target.value)} value={textOnlyMessage} />
                <br />
              </div>
              <div style={{ display: 'flex', flex: 0.5, justifyContent: 'flex-start', alignItems: 'center', padding: '0em, 0.25em, 0em, 0.25em' }}>
                <Form.Control type="number" step={1} min={0} max={500} onChange={(event) => setTextOnlyMessage(event.target.value)} value={textOnlyMessage} style={{ width: '4.5em', marginLeft: '0.25em', marginRight: '0.25em' }} />
                <p style={{ margin: '0em' }}>Mensaje{parseInt(textOnlyMessage) === 1 ? '' : 's'}</p>
              </div>
            </div>

            {/* Mensajería instantanea (imagen) */}
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div style={{ flex: 2 }}>
                <Form.Label>Mensajería instantanea (imagen)</Form.Label>
                <Form.Control type="range" step={1} min={0} max={500} onChange={(event) => setImageMessage(event.target.value)} value={imageMessage} />
                <br />
              </div>
              <div style={{ display: 'flex', flex: 0.5, justifyContent: 'flex-start', alignItems: 'center', padding: '0em, 0.25em, 0em, 0.25em' }}>
                <Form.Control type="number" step={1} min={0} max={500} onChange={(event) => setImageMessage(event.target.value)} value={imageMessage} style={{ width: '4.5em', marginLeft: '0.25em', marginRight: '0.25em' }} />
                <p style={{ margin: '0em' }}>Mensaje{parseInt(imageMessage) === 1 ? '' : 's'}</p>
              </div>
            </div>

            {/* Mensajería instantanea (video) */}
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div style={{ flex: 2 }}>
                <Form.Label>Mensajería instantanea (video)</Form.Label>
                <Form.Control type="range" step={1} min={0} max={500} onChange={(event) => setVideoMessage(event.target.value)} value={videoMessage} />
                <br />
              </div>
              <div style={{ display: 'flex', flex: 0.5, justifyContent: 'flex-start', alignItems: 'center', padding: '0em, 0.25em, 0em, 0.25em' }}>
                <Form.Control type="number" step={1} min={0} max={500} onChange={(event) => setVideoMessage(event.target.value)} value={videoMessage} style={{ width: '4.5em', marginLeft: '0.25em', marginRight: '0.25em' }} />
                <p style={{ margin: '0em' }}>Mensaje{parseInt(videoMessage) === 1 ? '' : 's'}</p>
              </div>
            </div>

            {/* Mensajería instantanea (nota de voz) */}
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div style={{ flex: 2 }}>
                <Form.Label>Mensajería instantanea (nota de voz)</Form.Label>
                <Form.Control type="range" step={1} min={0} max={500} onChange={(event) => setVoiceMessage(event.target.value)} value={voiceMessage} />
                <br />
              </div>
              <div style={{ display: 'flex', flex: 0.5, justifyContent: 'flex-start', alignItems: 'center', padding: '0em, 0.25em, 0em, 0.25em' }}>
                <Form.Control type="number" step={1} min={0} max={500} onChange={(event) => setVoiceMessage(event.target.value)} value={voiceMessage} style={{ width: '4.5em', marginLeft: '0.25em', marginRight: '0.25em' }} />
                <p style={{ margin: '0em' }}>Mensaje{parseInt(voiceMessage) === 1 ? '' : 's'}</p>
              </div>
            </div>

            {/* Llamada de voz */}
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div style={{ flex: 2 }}>
                <Form.Label>Llamada de voz</Form.Label>
                <Form.Control type="range" step={1} min={0} max={360} onChange={(event) => setVoiceCall(event.target.value)} value={voiceCall} />
                <br />
              </div>
              <div style={{ display: 'flex', flex: 0.5, justifyContent: 'flex-start', alignItems: 'center', padding: '0em, 0.25em, 0em, 0.25em' }}>
                <Form.Control type="number" step={1} min={0} max={360} onChange={(event) => setVoiceCall(event.target.value)} value={voiceCall} style={{ width: '4.5em', marginLeft: '0.25em', marginRight: '0.25em' }} />
                <p style={{ margin: '0em' }}>Minuto{parseInt(voiceCall) === 1 ? '' : 's'}</p>
              </div>
            </div>

            {/* Videollamada */}
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div style={{ flex: 2 }}>
                <Form.Label>Videollamada</Form.Label>
                <Form.Control type="range" step={1} min={0} max={360} onChange={(event) => setVideoCall(event.target.value)} value={videoCall} />
                <br />
              </div>
              <div style={{ display: 'flex', flex: 0.5, justifyContent: 'flex-start', alignItems: 'center', padding: '0em, 0.25em, 0em, 0.25em' }}>
                <Form.Control type="number" step={1} min={0} max={360} onChange={(event) => setVideoCall(event.target.value)} value={videoCall} style={{ width: '4.5em', marginLeft: '0.25em', marginRight: '0.25em' }} />
                <p style={{ margin: '0em' }}>Minuto{parseInt(videoCall) === 1 ? '' : 's'}</p>
              </div>
            </div>

            {/* Musica en streaming */}
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div style={{ flex: 2 }}>
                <Form.Label>Reproducción de música en streaming</Form.Label>
                <Form.Control type="range" step={1} min={0} max={360} onChange={(event) => setMusicStreaming(event.target.value)} value={musicStreaming} />
                <br />
              </div>
              <div style={{ display: 'flex', flex: 0.5, justifyContent: 'flex-start', alignItems: 'center', padding: '0em, 0.25em, 0em, 0.25em' }}>
                <Form.Control type="number" step={1} min={0} max={360} onChange={(event) => setMusicStreaming(event.target.value)} value={musicStreaming} style={{ width: '4.5em', marginLeft: '0.25em', marginRight: '0.25em' }} />
                <p style={{ margin: '0em' }}>Minuto{parseInt(musicStreaming) === 1 ? '' : 's'}</p>
              </div>
            </div>

            {/* Video en streaming */}
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div style={{ flex: 2 }}>
                <Form.Label>Reproducción de video en streaming</Form.Label>
                <Form.Control type="range" step={1} min={0} max={360} onChange={(event) => setVideoStreaming(event.target.value)} value={videoStreaming} />
                <br />
              </div>
              <div style={{ display: 'flex', flex: 0.5, justifyContent: 'flex-start', alignItems: 'center', padding: '0em, 0.25em, 0em, 0.25em' }}>
                <Form.Control type="number" step={1} min={0} max={360} onChange={(event) => setVideoStreaming(event.target.value)} value={videoStreaming} style={{ width: '4.5em', marginLeft: '0.25em', marginRight: '0.25em' }} />
                <p style={{ margin: '0em' }}>Minuto{parseInt(videoStreaming) === 1 ? '' : 's'}</p>
              </div>
            </div>

            {/* Peliculas y/o series */}
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div style={{ flex: 2 }}>
                <Form.Label>Reproducción de películas y/o series en línea</Form.Label>
                <Form.Control type="range" step={1} min={0} max={360} onChange={(event) => setMovieStreaming(event.target.value)} value={movieStreaming} />
                <br />
              </div>
              <div style={{ display: 'flex', flex: 0.5, justifyContent: 'flex-start', alignItems: 'center', padding: '0em, 0.25em, 0em, 0.25em' }}>
                <Form.Control type="number" step={1} min={0} max={360} onChange={(event) => setMovieStreaming(event.target.value)} value={movieStreaming} style={{ width: '4.5em', marginLeft: '0.25em', marginRight: '0.25em' }} />
                <p style={{ margin: '0em' }}>Minuto{parseInt(movieStreaming) === 1 ? '' : 's'}</p>
              </div>
            </div>

            {/* Uso de mapas */}
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div style={{ flex: 2 }}>
                <Form.Label>Uso de mapas</Form.Label>
                <Form.Control type="range" step={1} min={0} max={360} onChange={(event) => setMapsUsage(event.target.value)} value={mapsUsage} />
                <br />
              </div>
              <div style={{ display: 'flex', flex: 0.5, justifyContent: 'flex-start', alignItems: 'center', padding: '0em, 0.25em, 0em, 0.25em' }}>
                <Form.Control type="number" step={1} min={0} max={360} onChange={(event) => setMapsUsage(event.target.value)} value={mapsUsage} style={{ width: '4.5em', marginLeft: '0.25em', marginRight: '0.25em' }} />
                <p style={{ margin: '0em' }}>Minuto{parseInt(mapsUsage) === 1 ? '' : 's'}</p>
              </div>
            </div>

            {/* Correos electrónicos enviados y/o recibidos sin archivos adjuntos */}
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div style={{ flex: 2 }}>
                <Form.Label>Correos electrónicos enviados y/o recibidos sin archivos adjuntos</Form.Label>
                <Form.Control type="range" step={1} min={0} max={1000} onChange={(event) => setEmail(event.target.value)} value={email} />
                <br />
              </div>
              <div style={{ display: 'flex', flex: 0.5, justifyContent: 'flex-start', alignItems: 'center', padding: '0em, 0.25em, 0em, 0.25em' }}>
                <Form.Control type="number" step={1} min={0} max={1000} onChange={(event) => setEmail(event.target.value)} value={email} style={{ width: '4.5em', marginLeft: '0.25em', marginRight: '0.25em' }} />
                <p style={{ margin: '0em' }}>Correo{parseInt(email) === 1 ? '' : 's'}</p>
              </div>
            </div>

            {/* Correos electrónicos enviados con archivos adjuntos */}
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div style={{ flex: 2 }}>
                <Form.Label>Correos electrónicos enviados con archivos adjuntos</Form.Label>
                <Form.Control type="range" step={1} min={0} max={1000} onChange={(event) => setEmailWithAttachment(event.target.value)} value={emailWithAttachment} />
                <br />
              </div>
              <div style={{ display: 'flex', flex: 0.5, justifyContent: 'flex-start', alignItems: 'center', padding: '0em, 0.25em, 0em, 0.25em' }}>
                <Form.Control type="number" step={1} min={0} max={1000} onChange={(event) => setEmailWithAttachment(event.target.value)} value={emailWithAttachment} style={{ width: '4.5em', marginLeft: '0.25em', marginRight: '0.25em' }} />
                <p style={{ margin: '0em' }}>Correo{parseInt(emailWithAttachment) === 1 ? '' : 's'}</p>
              </div>
            </div>

            {/* Navegacion por la web */}
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div style={{ flex: 2 }}>
                <Form.Label>Navegacion por la web</Form.Label>
                <Form.Control type="range" step={1} min={0} max={360} onChange={(event) => setWebBrowsing(event.target.value)} value={webBrowsing} />
                <br />
              </div>
              <div style={{ display: 'flex', flex: 0.5, justifyContent: 'flex-start', alignItems: 'center', padding: '0em, 0.25em, 0em, 0.25em' }}>
                <Form.Control type="number" step={1} min={0} max={360} onChange={(event) => setWebBrowsing(event.target.value)} value={webBrowsing} style={{ width: '4.5em', marginLeft: '0.25em', marginRight: '0.25em' }} />
                <p style={{ margin: '0em' }}>Minuto{parseInt(webBrowsing) === 1 ? '' : 's'}</p>
              </div>
            </div>

            {/* Descarga y/o actualizacion de aplicaciones */}
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div style={{ flex: 2 }}>
                <Form.Label>Descarga y/o actualización de aplicaciones</Form.Label>
                <Form.Control type="range" step={1} min={0} max={10} onChange={(event) => setAppDownload(event.target.value)} value={appDownload} />
                <br />
              </div>
              <div style={{ display: 'flex', flex: 0.5, justifyContent: 'flex-start', alignItems: 'center', padding: '0em, 0.25em, 0em, 0.25em' }}>
                <Form.Control type="number" step={1} min={0} max={10} onChange={(event) => setAppDownload(event.target.value)} value={appDownload} style={{ width: '4.5em', marginLeft: '0.25em', marginRight: '0.25em' }} />
                <p style={{ margin: '0em' }}>App{parseInt(appDownload) === 1 ? '' : 's'}</p>
              </div>
            </div>

          </Form.Group>
        </Form>


      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-dark" style={{ position: 'absolute', left: '0.5em' }} onClick={reiniciar}>Reiniciar</Button>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <img src={logoULV} alt='' style={{ height: '25px', width: '25px', marginRight: '0.5em', userSelect: 'none' }} />
          <p style={{ margin: 0, userSelect: 'none' }}>UP-Link de Venezuela.{width > 575 ? ' RIF: J-29355952-9' : ''}</p>
        </div>
      </Modal.Footer>
    </Modal>
  )
}

export default CalcularConsumoModal