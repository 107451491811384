import React from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './component-style.css';

//IMPORT LOGOTIPO
import icono_logo from '../assets/logotipo/icono-ulv.png';
// import texto_logo from '../assets/logotipo/texto-logo-ulv.png';

//ESTE COMPONENTE VISUAL SE ENCARGA DE REPRESENTAR LA BARRA DE NAVEGACION EN LA PARTE SUPERIOR Y SUS ELEMENTOS
const Navegacion = () => {

  return (
    <Navbar variant="light" expand="lg" collapseOnSelect className="fixed-top navegacion">
      <Link to="/">
        <Navbar.Brand href="/" className="mr-5">
          <img src={icono_logo} alt="logo" width='40px' height="40px" className="mr-2"></img>
          {/* <img src={texto_logo} alt="logo" height='15px' className="mr-2"></img> */}
          <h1 id="marca-navegacion">UP-Link de Venezuela</h1>
        </Navbar.Brand>
      </Link>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <div className="mr-auto"></div>
        <Nav className="mx-auto">
          <Link to="/" style={{ textDecoration: "none" }}>
            <Nav.Link href="/" className="enlace-navegacion" style={{ color: "black" }}>INICIO</Nav.Link>
          </Link>

          <Link to="/conocenos" style={{ textDecoration: "none" }}>
            <Nav.Link href="/conocenos" className="enlace-navegacion" style={{ color: "black" }}>CONÓCENOS</Nav.Link>
          </Link>

          <Link to="/servicios" style={{ textDecoration: "none" }}>
            <Nav.Link href="/servicios" className="enlace-navegacion" style={{ color: "black" }}>SERVICIOS</Nav.Link>
          </Link>

          <Link to="/eventos" style={{ textDecoration: "none" }}>
            <Nav.Link href="/eventos" className="enlace-navegacion" style={{ color: "black" }}>EVENTOS</Nav.Link>
          </Link>

          {/* <Link to="/clientes" style={{ textDecoration: "none" }}>
            <Nav.Link href="/clientes" className="enlace-navegacion" style={{ color: "black" }}>CLIENTES</Nav.Link>
          </Link> */}

          <Link to="/galeria-de-fotos" style={{ textDecoration: "none" }}>
            <Nav.Link href="/galeria-de-fotos" className="enlace-navegacion" style={{ color: "black" }}>GALERÍA</Nav.Link>
          </Link>

          {/* <Link to="/contacto" style={{textDecoration: "none"}}>
                        <Nav.Link href="/contacto" className="enlace-navegacion" style={{color: "black"}}>CONTÁCTANOS</Nav.Link>
                      </Link> */}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  )

}


export default Navegacion;