import React from 'react';
import {Card, CardColumns} from 'react-bootstrap';
import './component-style.css';

import AP from '../assets/imagenes_clientes/associated-press.png';
import directv from '../assets/imagenes_clientes/directv.jpeg';
import cnn from '../assets/imagenes_clientes/cnn.png';
import conmebol from '../assets/imagenes_clientes/conmebol.jpg';
import foxsports from '../assets/imagenes_clientes/foxsports.png';
import meridianotv from '../assets/imagenes_clientes/meridianotv.png';
import venevision from '../assets/imagenes_clientes/venevision.png';
import espn from '../assets/imagenes_clientes/espn.png';
import simpletv from '../assets/imagenes_clientes/simpletv.png';
import ivc from '../assets/imagenes_clientes/ivc.png';
import ole from '../assets/imagenes_clientes/ole.png';


class Clientes extends React.Component{

    render(){        
        return  <div>
                    <h2 className="titulo-principal"> Nuestros Clientes </h2>
                    <CardColumns className="mx-auto mt-5 text-center w-75">
                            <Card style={tarjeta_cliente}>
                                <Card.Img src={AP} />
                            </Card>

                            <Card style={tarjeta_cliente}>
                                <Card.Img src={directv}/>
                            </Card>
                            

                            <Card style={tarjeta_cliente}>
                                <Card.Img src={cnn} />
                            </Card>

                            <Card style={tarjeta_cliente}>
                                <Card.Img src={espn} />
                            </Card>
                            
                            <Card style={tarjeta_cliente}>
                                <Card.Img src={simpletv} />
                            </Card>

                            <Card style={tarjeta_cliente}>
                                <Card.Img src={conmebol} />
                            </Card>

                            <Card style={tarjeta_cliente}>
                                <Card.Img src={foxsports} />
                            </Card>
                        
                            <Card style={tarjeta_cliente}>
                                <Card.Img src={meridianotv} />
                            </Card>
                            
                            <Card style={tarjeta_cliente}>
                                <Card.Img src={venevision} className="w-75 mx-auto"/>
                            </Card>

                            <Card style={tarjeta_cliente}>
                                <Card.Img src={ivc} />
                            </Card>

                            <Card style={tarjeta_cliente}>
                                <Card.Img src={ole} />
                            </Card>

                    </CardColumns>
                </div>
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }
    
}

const tarjeta_cliente = {
    border:"none",
    width:"90%",
    margin:"auto",
    marginBottom:"3em"
}

export default Clientes;