import React from 'react';
import {Card, CardColumns} from 'react-bootstrap';
import './component-style.css';

//IMPORT DE IMAGENES
import img_01 from '../assets/galeria_fotos/img-01.jfif';
import img_02 from '../assets/galeria_fotos/img-02.jfif';
import img_03 from '../assets/galeria_fotos/img-03.jfif';
import img_04 from '../assets/galeria_fotos/img-04.jfif';
import img_05 from '../assets/galeria_fotos/img-05.jfif';
import img_06 from '../assets/galeria_fotos/img-06.jfif';
import img_07 from '../assets/galeria_fotos/img-07.jpg';
import img_08 from '../assets/galeria_fotos/img-08.jpg';
import img_09 from '../assets/galeria_fotos/img-09.jpg';
import img_10 from '../assets/galeria_fotos/img-10.jpg';
import img_11 from '../assets/galeria_fotos/img-11.jpg';
import img_12 from '../assets/galeria_fotos/img-12.jpg';
import img_13 from '../assets/galeria_fotos/img-13.jpg';
import img_14 from '../assets/galeria_fotos/img-14.jpg';
import img_15 from '../assets/galeria_fotos/img-15.jpg';
import img_16 from '../assets/galeria_fotos/img-16.jfif';

//IMAGENES DE EQUIPOS
import img_e_01 from '../assets/galeria_fotos/img-e-01.jpg';
import img_e_02 from '../assets/galeria_fotos/img-e-02.jpg';
import img_e_03 from '../assets/galeria_fotos/img-e-03.jpg';
import img_e_04 from '../assets/galeria_fotos/img-e-04.jpg';
import img_e_05 from '../assets/galeria_fotos/img-e-05.jfif';
import img_e_06 from '../assets/galeria_fotos/img-e-06.jfif';
import img_e_07 from '../assets/galeria_fotos/img-e-07.jfif';
import img_e_08 from '../assets/galeria_fotos/img-e-08.jfif';
import img_e_09 from '../assets/galeria_fotos/img-e-09.jfif';
import img_e_10 from '../assets/galeria_fotos/img-e-10.jfif';
import img_e_11 from '../assets/galeria_fotos/img-e-11.jfif';

class GaleriaDeFotos extends React.Component{

    render(){        
        return  <div>
                    <h2 className="titulo-principal">Galería de fotos</h2>
                    <div>
                        <CardColumns className="mx-3 text-center">

                            <Card style={tarjeta_imagen} className="tarjeta-imagen">
                                <Card.Img style={imagen} src={img_01} />
                                {/* <Card.Footer className="footer-tarjetas">prueba de footer</Card.Footer> */}
                            </Card>

                            <Card style={tarjeta_imagen} className="tarjeta-imagen">
                                <Card.Img style={imagen} src={img_02}/>
                            </Card>

                            <Card style={tarjeta_imagen} className="tarjeta-imagen">
                                <Card.Img style={imagen} src={img_03} />
                            </Card>

                            <Card style={tarjeta_imagen} className="tarjeta-imagen">
                                <Card.Img style={imagen} src={img_04} />
                            </Card>

                            <Card style={tarjeta_imagen} className="tarjeta-imagen">
                                <Card.Img style={imagen} src={img_05} />
                            </Card>

                            <Card style={tarjeta_imagen} className="tarjeta-imagen">
                                <Card.Img style={imagen} src={img_06} />
                            </Card>
                            
                            <Card style={tarjeta_imagen} className="tarjeta-imagen">
                                <Card.Img style={imagen} src={img_07} />
                            </Card>

                            <Card style={tarjeta_imagen} className="tarjeta-imagen">
                                <Card.Img style={imagen} src={img_08} />
                            </Card>

                            <Card style={tarjeta_imagen} className="tarjeta-imagen">
                                <Card.Img style={imagen} src={img_09} />
                            </Card>

                            <Card style={tarjeta_imagen} className="tarjeta-imagen">
                                <Card.Img style={imagen} src={img_10} />
                            </Card>

                            <Card style={tarjeta_imagen} className="tarjeta-imagen">
                                <Card.Img style={imagen} src={img_11} />
                            </Card>

                            <Card style={tarjeta_imagen} className="tarjeta-imagen">
                                <Card.Img style={imagen} src={img_12} />
                            </Card>

                            <Card style={tarjeta_imagen} className="tarjeta-imagen">
                                <Card.Img style={imagen} src={img_13} />
                            </Card>

                            <Card style={tarjeta_imagen} className="tarjeta-imagen">
                                <Card.Img style={imagen} src={img_14} />
                            </Card>

                            <Card style={tarjeta_imagen} className="tarjeta-imagen">
                                <Card.Img style={imagen} src={img_15} />
                            </Card>

                            <Card style={tarjeta_imagen} className="tarjeta-imagen">
                                <Card.Img style={imagen} src={img_16} />
                            </Card>

                            {/* IMAGENES DE EQUIPOS */}

                            <Card style={tarjeta_imagen} className="tarjeta-imagen">
                                <Card.Img style={imagen} src={img_e_01} />
                            </Card>

                            <Card style={tarjeta_imagen} className="tarjeta-imagen">
                                <Card.Img style={imagen} src={img_e_02} />
                            </Card>

                            <Card style={tarjeta_imagen} className="tarjeta-imagen">
                                <Card.Img style={imagen} src={img_e_03} />
                            </Card>

                            <Card style={tarjeta_imagen} className="tarjeta-imagen">
                                <Card.Img style={imagen} src={img_e_04} />
                            </Card>

                            <Card style={tarjeta_imagen} className="tarjeta-imagen">
                                <Card.Img style={imagen} src={img_e_05} />
                            </Card>

                            <Card style={tarjeta_imagen} className="tarjeta-imagen">
                                <Card.Img style={imagen} src={img_e_06} />
                            </Card>

                            <Card style={tarjeta_imagen} className="tarjeta-imagen">
                                <Card.Img style={imagen} src={img_e_07} />
                            </Card>

                            <Card style={tarjeta_imagen} className="tarjeta-imagen">
                                <Card.Img style={imagen} src={img_e_08} />
                            </Card>

                            <Card style={tarjeta_imagen} className="tarjeta-imagen">
                                <Card.Img style={imagen} src={img_e_09} />
                            </Card>

                            <Card style={tarjeta_imagen} className="tarjeta-imagen">
                                <Card.Img style={imagen} src={img_e_10} />
                            </Card>

                            <Card style={tarjeta_imagen} className="tarjeta-imagen">
                                <Card.Img style={imagen} src={img_e_11} />
                            </Card>

                        </CardColumns>
                    </div>
                </div>
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }
    
}

const tarjeta_imagen = {
    border:"none",
    borderRadius: "25px",
    width:"90%", 
    margin:"auto", 
    marginBottom:"3em",
}

const imagen = {
    borderRadius:"10px",
    // boxShadow:"-8px 8px 10px gray"
    boxShadow: "8px 8px 10px gray",
    // transition: "transform 0.5s",
}

export default GaleriaDeFotos;