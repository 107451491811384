import React from 'react';

import img_01 from '../../assets/imagenes_inicio/inicio-01.jfif'
import img_02 from '../../assets/imagenes_inicio/inicio-02.jfif'
import img_03 from '../../assets/imagenes_inicio/inicio-03.jpg'
import img_04 from '../../assets/imagenes_inicio/inicio-04.jfif'

import '../component-style.css';

import {Carousel} from 'react-bootstrap';


class Carrusel extends React.Component{

    render(){        
        return  <Carousel className="mx-auto mt-3 carrusel" indicators={false}>
                    <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src={img_03}
                        alt="First slide"
                    />
                    <Carousel.Caption style={{padding:0, marginBottom: '-0.5em'}} className="caption-carrusel">
                        <h2>Confiabilidad</h2>
                        <p>Transmisión en vivo de cualquier evento vía satélite con la mas alta seguridad y estabilidad</p>
                    </Carousel.Caption>
                    </Carousel.Item>

                    <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src={img_01}
                        alt="Second slide"
                    />
                    <Carousel.Caption style={{padding:0, marginBottom: '-0.5em'}} className="caption-carrusel">
                        <h2>Robustéz</h2>
                        <p>Equipos de respaldo y reduntantes para garantizar tu transmisión</p>
                    </Carousel.Caption>
                    </Carousel.Item>

                    <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src={img_02}
                        alt="Third slide"
                    />
                    <Carousel.Caption style={{padding:0, marginBottom: '-0.5em'}} className="caption-carrusel">
                        <h2>En cualquier lugar</h2>
                        <p>Nos acercamos a tí y a tus requerimientos, sea cual sea la localización.</p>
                    </Carousel.Caption>
                    </Carousel.Item>

                    <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src={img_04}
                        alt="Cuarta slide"
                    />
                    <Carousel.Caption style={{padding:0, marginBottom: '-0.5em'}} className="caption-carrusel">
                        <h2>Tecnología</h2>
                        <p>Ponemos a tu disposición la mas alta tecnología en equipos de transmisión satelital.</p>
                    </Carousel.Caption>
                    </Carousel.Item>

                </Carousel>
    }
    
}

export default Carrusel;