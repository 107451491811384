import React from 'react'
import { Alert } from 'react-bootstrap'

const LinkContainer = ({ links, width, onLinkClick }) => {
  return (
    <Alert variant='info' style={{ width: width ? width : '85%', margin: 'auto', marginTop: '1em', borderRadius: '10px', boxShadow: '2px 2px 15px rgba(0, 0, 0, 0.125)', textAlign: 'start', userSelect: 'none' }}>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-around' }}>
        {links.map((link, index) => {
          return <p key={index} style={{textAlign: 'center', marginBottom: '0px', cursor: 'pointer'}} onClick={() => onLinkClick(link)}>{link.text}</p>
        })}
      </div>
    </Alert>
  )
}

export default LinkContainer