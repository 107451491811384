// import logo from './logo.svg';
import Navegacion from './components/Navegacion.js';
import Pie from './components/Pie.js';
import Inicio from './components/Inicio.js';
//import Contactanos from './components/Contactanos.js';
import Servicios from './components/Servicios.js';
import Eventos from './components/Eventos.js';
import Clientes from './components/Clientes.js';
import GaleriaDeFotos from './components/GaleriaDeFotos.js';

import './App.css';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import Conocenos from './components/Conocenos.js';

function App() {
  return (
    <Router>
    <div className="App">
      {/* <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header> */}
      <Navegacion/>
      <Switch>
        <Route path="/" exact component={Inicio}/>
        {/* <Route path="/contacto" component={Contactanos}/> */}
        <Route path="/conocenos" component={Conocenos}/>
        <Route path="/servicios" component={Servicios}/>
        <Route path="/eventos" component={Eventos}/>
        <Route path="/clientes" component={Clientes}/>
        <Route path="/galeria-de-fotos" component={GaleriaDeFotos}/>
      </Switch>
      <Pie/>
    </div>
    </Router>
  );
}

export default App;
