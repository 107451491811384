import React, { useState, useEffect } from 'react';
import Carrusel from './sub-components/Carrusel.js';
import { Button, Card, CardGroup } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import './component-style.css';

import img_mapa from '../assets/imagenes_inicio/mapa-vial-2.png';
import img_conocenos from '../assets/conocenos.jpg';
import img_handshake from '../assets/imagenes_inicio/handshake.jpg';

import BannerComunicado from './BannerComunicado/BannerComunicado.jsx';
import LinkContainer from './LinkContainer/LinkContainer.jsx';
import CalcularConsumoModal from './CalcularConsumoModal/CalcularConsumoModal.jsx';

import useWindowDimensions from '../hooks/useWindowDimensions.jsx';

const links = [
    {
        text: 'Mide tu velocidad',
        url: 'https://fast.com'
    },
    {
        text: 'Estimar consumo',
        url: ''
    },
]

//ESTE COMPONENTE VISUAL SE ENCARGA DE REPRESENTAR LA BARRA DE NAVEGACION EN LA PARTE SUPERIOR Y SUS ELEMENTOS
const Inicio = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const { width } = useWindowDimensions();

    const [showCalcularConsumoModal, setshowCalcularConsumoModal] = useState(false);

    const onClickedLink = (link) => {
        if (link.text === 'Estimar consumo') {
            setshowCalcularConsumoModal(true)
        } else {
            window.open(link.url)
        }
    }

    return (
        <div>
            <CalcularConsumoModal show={showCalcularConsumoModal} onClose={() => setshowCalcularConsumoModal(false)} />

            <div className='banner-container'>
                <BannerComunicado width={width > 575 ? '70%' : '95%'} logo scrollable text={'NO ESTA PERMITIDO QUE EL SERVICIO CONTRATADO SEA EMPLEADO DE FORMA DIRECTA O A TRAVÉS DE TERCEROS PARA LA REVENTA DEL MISMO, LO CUAL PODRÁ SER SANCIONADO POR CONATEL POR INCURRIR EN DELITOS TIPIFICADOS EN LA LEGISLACIÓN VIGENTE.'} />
                <LinkContainer width={width > 575 ? '26.5%' : '95%'} links={links} onLinkClick={onClickedLink} class='link-container' />
            </div>

            <Carrusel />

            <CardGroup style={{ margin: "2em", marginTop: "6em", marginBottom: "6em" }}>
                <Card style={{ borderRadius: "10px" }} className="tarjetas-inicio">
                    <Card.Body>
                        <Card.Title className="titulo">¿Por qué nosotros?</Card.Title>
                        <Card.Text className="texto-tarjetas">
                            Somos una empresa líder en transmisiones vía satélite a lo largo y ancho del territorio nacional, ajustandonos a tus necesidades.
                        </Card.Text>
                    </Card.Body>
                    <Card.Img variant="top" src={img_mapa} />
                    <Card.Footer style={{ background: "white", border: "none", borderRadius: "10px" }}>
                        <Link to="/conocenos">
                            <Button variant="outline-dark" className="texto-tarjetas">Conócenos</Button>
                        </Link>
                    </Card.Footer>
                </Card>

                <Card style={{ borderRadius: "10px" }} className="tarjetas-inicio">
                    <Card.Body>
                        <Card.Title className="titulo">Nuestros servicios</Card.Title>
                        <Card.Text className="texto-tarjetas">
                            Ponemos a tu disposicion distintos servicios de la mas alta calidad y confiabilidad, de la mano con las mejores tecnologías.
                        </Card.Text>
                    </Card.Body>
                    <Card.Img variant="top" src={img_conocenos} />
                    <Card.Footer style={{ background: "white", border: "none", borderRadius: "10px" }}>
                        <Link to="/servicios">
                            <Button variant="outline-dark" className="texto-tarjetas">Servicios</Button>
                        </Link>
                    </Card.Footer>
                </Card>
                <Card style={{ borderRadius: "10px" }} className="tarjetas-inicio">
                    <Card.Body>
                        <Card.Title className="titulo">Alta reputación</Card.Title>
                        <Card.Text className="texto-tarjetas">
                            Trabajamos con clientes nacionales e internacionales reconocidos, manteniendo siempre los más altos estándares en la calidad de servicio y estabilidad.
                        </Card.Text>
                    </Card.Body>
                    <Card.Img variant="top" src={img_handshake} />
                    <Card.Footer style={{ background: "white", border: "none", borderRadius: "10px" }}>
                        <Link to="/clientes">
                            <Button variant="outline-dark" className="texto-tarjetas">Clientes</Button>
                        </Link>
                    </Card.Footer>
                </Card>
            </CardGroup>
        </div>
    )

}

export default Inicio;